.select-item-wrapper {
    z-index: none ;
    position: relative;
    tbody tr.active {
        background-color:#be8e58  !important;
    }
    tbody tr:hover {
        background-color:#be8e58;
        color: black;
    }
    .options-container {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        z-index: 1100;
        min-width: 100%;
        box-shadow: 0px 5px 10px rgba(30, 32, 37, 0.12);
        overflow-y: auto;
        table{
            border: 2px solid #000000 !important;
        }
        thead{
            position: sticky;
            top: 0;
            z-index: 1200 !important;
        }
        tbody{
            background-color: $wh-modal-bg !important;
        }
    }
    .item.active {
        background: #be8e58;
        color: black !important;
      }
      
    .item:hover {
    cursor: pointer;
    }
      
}
