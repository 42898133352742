.po-smart-search-wrapper {
  .item {
    border: 1px solid rgb(0,0,0);
    // margin: 5px;
    // padding: 5px;
  }
  
  .item.active {
    background : #be8e58;
  }
  
  .item:hover {
    cursor: pointer;
  }
}