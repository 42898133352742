.wh-body-bg{
    background-color: $wh-body-bg;
}
.wh-color-white{
    color: $wh-body-color;
}
.wh-card-bg{
    background-color: $wh-card-bg !important;
}
.wh-input-bg{
    background-color: $wh-input-bg;
}
span{
    font-weight: $font-weight-bold;
}

.wh-btn{
    background-color: $light;
    color: $wh-link-color;
    font-weight: $font-weight-bold;
}
.wh-btn-warning{
    background-color: #fff4e4;
}
.wh-link-color{
    color: $link-color;
    opacity: 1;
}

.wh-link-color-green{
    color: $wh-table-link-color;
}

.wh-card-border{
    border: 1px solid $border-color;
}
.wh-btn-danger {
    background-color: $light;
    color: $danger;
    font-weight: $font-weight-bold;
    &:hover {
        background-color: $danger;
        color: $wh-body-color;
        /* Other hover styles */
    }
}

.pill-bg{
    background-color: rgba(255, 255, 255, 0.067) !important;
}

.wh-text-dark{
    color: black !important;
}
.wh-text-white{
    color: #ffffff !important;
}
.form-label{
    color: $wh-body-color;
}
.input-step input{
    background:rgba(74, 74, 249, 0.667) !important;
}

.page-link{
    color: #09f;
}

.rounded-badge {
    width: 3rem;
    height: 3rem;
    background-color: #df1dd1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .badge-number {
    color: white; 
    font-size: 18px;
    font-weight: bold;
  }
  
.whbrand-navs{
    // 
// _nav.scss
// 

.nav-tabs,
.nav-pills {
    >li {
        >a {
            color: #000000;
            font-weight: $font-weight-bold;
        }
    }
}
}

.nav-tabs,
.nav-pills {
    >li {
        >a {
            color: black;
            font-weight: $font-weight-bold;
        }
    }
}

.nav-pills {
    >a {
        color: black;
        font-weight: $font-weight-bold;
    }
}
.nav-pills .nav-link.active{
    color: #fff;
    background-color: $wh-nav-pill-active-bg-color;
}
.nav-link:hover{
    color: $wh-nav-pill-active-bg-color;
}
.nav-tabs-custom {
    .nav-item {
        .nav-link {
        
            font-weight: $font-weight-bold;
        }
    }
}
.form-control {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.form-select{
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    option {
        font-weight: $font-weight-bold;
    }
}

.modal {
    h1,h2,h3,h4,h5,h6{
        color: $wh-body-color;
    }
    .modal-header{
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}
.custom-modal{
    .modal-header{
        box-shadow:none;
    }
   .modal-content{
    background-color: #fff;
    box-shadow: none;
    color: #212529;
    .btn-close{
        background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    }
   }
}

.select-item-number-wrapper{
    color: #1553ff;
}
.select-description-wrapper{
    color: #1553ff;
}

.badge-soft-primary{
    color: $wh-link-color !important;
}

.table{
    font-family: $font-family-primary !important;
    border-collapse: separate;
    border-spacing : 0px;
    // background-color: $wh-body-bg;
    background-color: #e7e7e7;

    th,td {
        font-weight: $font-weight-bold;
        height: 2rem;
    }

    .wh-table-border{
        border: $wh-table-border;
    }

    th{
        border: $wh-table-border;
        background-color: $wh-table-th-bg-color;
    }
    td{
        font-size: 0.9rem !important;
        border: 1px solid #000000;
    }
    .wh-table-head{
        color: $wh-table-th-color;
        border-color: $table-border-color;
        background-color: $wh-table-th-bg-color;
    }
}
.table-container {
    overflow: auto;
    max-height: calc(100vh - 210px);
  }
.table-container-bundle{
    overflow: auto;
    max-height: calc(100vh - 400px);
}
.table-container-po {
    overflow: auto;
    max-height: calc(100vh - 250px);
  }
.table-container-po-new {
    overflow: auto;
    max-height: calc(100vh - 400px);
    height: calc(100vh - 400px);
    margin-bottom: 2rem;
  }
  
  .table-container-pos{
    overflow: auto;
    max-height: calc(100vh - 320px);
    height: calc(100vh - 320px);
    margin-bottom: 2rem;
  }

  .table-container-suplierreport{
    overflow: auto;
    max-height: calc(100vh - 230px);
  }

  .table-container-poanalysis{
    overflow: auto;
    max-height: calc(100vh - 340px);
  }

  .table-container-inventroypricingreport{
    overflow: auto;
    max-height: calc(100vh - 415px);
  }

  .table-container-statics{
    overflow: auto;
    max-height: calc(100vh - 408px);
  }

  .table-container-customersreport{
    overflow: auto;
    max-height: calc(100vh - 320px);
  }

  .table-container-customersreport-bal{
    overflow: auto;
    max-height: calc(100vh - 330px);
  }
  .table-container-customersreport-statics{
    overflow: auto;
    max-height: calc(100vh - 340px);
  }

  .table-container-customersroutesreport{
    overflow: auto;
    max-height: calc(100vh - 270px);
  }

  .table-container-payment-history{
    overflow: auto;
    max-height: calc(100vh - 340px);
  }

  .table-container-order-history{
    overflow: auto;
    max-height: calc(100vh - 290px);
  }

  .table-container-sales-history{
    overflow: auto;
    max-height: calc(100vh - 280px);
  }
  .table-container-sales-highest-order{
    overflow: auto;
    max-height: calc(100vh - 330px);
  }

  .table-container-inventory-purchases{
    overflow: auto;
    max-height: calc(100vh - 370px);
  }
  .table-container-inventory-sales{
    overflow: auto;
    max-height: calc(100vh - 355px);
  }
  .table-container-drivers-report{
    overflow: auto;
    max-height: calc(100vh - 330px);
  }
  .table-container-return-sales{
    overflow: auto;
    max-height: calc(100vh - 290px);
  }
// .whbrand-text-white{
//     color: #ffffff !important;
// }
// .whbrand-active-nav-item{
//     background-color: rgba(106, 74, 128, 0.6);
//     border: 2px solid #ffffff;
//     .sub-label {
//         font-weight: bold;
//         color: #ffffff !important;
//     }
// }
.wh-item-bg{
    background-color: $wh-badge-color !important;
    border: $wh-badge-border;
    color: #000000;
}

.wh-border-thick{
    border-width: 3px !important;
    border-color: $wh-border-color-group-items !important;
}

.form-switch {
    .form-check-input{
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba(255, 255, 255, 1)%27/%3e%3c/svg%3e");
    }
}
.input-group-text{
    background-color: $wh-input-bg;
}
.ck-content{
    p{
        color: black !important;
    }
}
// styles.scss

.minus{
    background-color: #fa2c2cb8 !important;
}

.plus{
    background-color: #47d7c4 !important;
}
.wh-pencil{
    color: $wh-pencil-color !important;
}
$breakpoints: (
  mobile: 600px,
  tablet: 768px,
  laptop: 1024px,
  desktop: 1200px,
);

.custom-table {
  @for $i from 1 through 100 {
    $px-height: #{$i}px;
    $vh-height: #{$i}vh;

    &.table-height-#{$px-height} {
      max-height: $px-height;
    }

    &.table-height-#{$vh-height} {
      max-height: $vh-height;
    }
    @media only screen and (max-width: 1200px) {
        // &.table-height-#{$vh-height} {
        //     max-height: calc(#{$vh-height} - 3vh);
        // }
    }
    
    @media only screen and (max-width: 1024px) {
       
        // &.table-height-#{$vh-height} {
        //     max-height: calc(#{$vh-height} - 3vh);
        // }
    }
      
    }
  
  
}

.flatpickr-day{
    color: black !important;
    &.selected{
        color: #ffffff !important;
    }
}

 .wh-text-muted{
    color: #fff !important;
 }

 .wh-modal-white-bg{
    color: #fff !important;
 }
 input[type="date"]::-webkit-calendar-picker-indicator {
    // filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.wh-menu-card{
    border-radius: 0 0.6rem 0.6rem 0;
    background-color: $wh-menu-card-bg-color;
}

.wh-report-card{
    border-radius: 0.6rem;
    background-color: $wh-menu-card-bg-color;
}

.wh-menu-card-text{
    color: $wh-menu-card-color !important;
}


.report-header{
    background-color: $report-title-color;
}
.wh-hover-bg-color{
    background-color: $wh-hover-bg-color;
}

.wh-bundle-bg{
    background-color:#ff00661a;
    border-radius: 5px;
}
.wh-bundle-title-bg{
    background-color:#f065489e;
    border-radius: 5px;
}
.wh-bundles-container{
    overflow: auto;
    max-height: calc(100vh - 250px);
}