.wh-header {
    position: sticky;
    top: 0;
    box-shadow: $wh-header-box-shadow;
    // border-color: $wh-header-border-color;
    z-index: 1000;
    background-color: $wh-header-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    .wh-navbar-nav {
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
    }
    .nav-item {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 0.2rem;
        margin: 0rem 1rem;
        &.active-nav-item {
            background-color: $wh-header-nav-active-bg-color;
            border: $wh-header-nav-active-border;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
            .main-label {
                font-weight: bold;
                color: $wh-header-color;
            }
            .sub-label {
                font-weight: bold;
                color: $wh-header-nav-active-color;
            }
        }
        .main-label {
            font-size: 0.8rem;
            color: $wh-header-color;
        }
        .header-icon {
            height: 34px;
            width: 34px;
            margin: 0.25rem 0rem;
        }
        .sub-label {
            font-size: 0.8rem;
            color: $wh-header-color;
        }
    }
}
