.warehouse-login-wrapper {
    .logo-image {
        width: 70%;
    }
}

.wh-background-image{
    margin: 0;
    padding: 0;
    position: relative;
   

}
.wh-background-image::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    background-image:$wh-background-image;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    // filter: grayscale(100%);
}

.wh-login-card{
    font-size: 16px;
    padding: 20px;
    color: #ffffff !important;
    background: linear-gradient(to right,$wh-login-card-bg-rgba, $wh-login-card-bg-rgba);
    border-radius: 25px 5px;
    filter: contrast(200%);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}