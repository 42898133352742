.sale-wh-wrapper {
    margin-top: 1rem;
    .w-60 {
        width: 60%;
    }
    .w-30 {
        width: 30%;
    }
    .w-48 {
        width: 48%;
    }
    .border-section {
        border: 1px solid #000000 !important;
        background-color: #F4D5A4;
    }
    .action-icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 22%;
    }
    .action-icon {
        width: 30px;
        height: 30px;
    }
    .sales-table {
        width: 100%;
        tr {
            td {
                &:first-child {
                    width: 50%;
                }
                &:not(:first-child) {
                    border: 1px solid #000000;
                }
            }
        }
        td {
            padding: 0.5rem;
        }
    }
}
