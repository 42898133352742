.select-option-wrapper {
    position: relative;
    .dropdown-container {
                    position: 'absolute';
                    top: '100%';
                    left: '0';
                    width: '100%';
                    background-color: $wh-modal-bg;
                    border: '1px solid #D3D3D3';
                    border-radius: '0.3rem';
                    max-height: '300px';
                    overflow-y: 'auto'; 
                    // overscroll-behavior:'contain';
                    z-index: 1100;
    }
    .item.active {
        background: #adadad3b;
      }
      
    .item:hover {
    cursor: pointer;
    background-color: rgb(233, 239, 241);
    color: black !important;
    }
}

.form-control{
  border: 1px solid black;
}

.form-select{
  border: 1px solid black;
}