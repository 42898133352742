.selectable-left-sidebar { 
    .item {
        padding: 8px 12px;
        font-size: 1.1rem;
        font-weight: 500;
        border-radius: 0 16px 16px 0;
        &.active {
            background-color: $wh-roles-background-color;
            font-weight: bold;
            color: black;
        }
    }
}