.warehouse-menu-wrapper {
    .menu-header {
        height: 70px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
    }
    .page-menu-content {
        margin-top: 90px;
    }
    .header-logo {
        height: 50%;
    }
    .menu-image-icon {
        height: 80px;
        width: 80px;
    }
    
}
// .form-control-login{
//     input{
//         background-color: #fff;
//     }
//     input::placeholder {
//         font-weight: bold;
//         color: #212529 !important; 
//     }
// }

.form-select-login{
   
    .form-select{
        color: #606264;
        &::placeholder{
            color: #606264 !important;
        }
        &:focus{
            border: $input-border-width solid #a961a9;
        }
        
        //background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-800}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !important;
    }
    
    .form-label{
        color: #ffffff !important;
        letter-spacing: 1px;
        font-size: 1rem;
        font-weight: 400;
    }
    select{
        display: block;
        width: 100%;
        height: auto;
        // padding: $input-padding-y $input-padding-x;
        font-family: $font-family-primary;
        font-size: 18px;
        font-weight: bolder !important;
        letter-spacing: 1px !important;
        color: #606264 !important;
        background-color: #fff !important;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: $input-border-radius;
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-800}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>")) !important;
    }
   
    

}

.form-control-login{
    
    >input {
        display: block;
        width: 100%;
        height: auto;
        // padding: $input-padding-y $input-padding-x;
        font-family: $font-family-primary;
        font-size: 18px;
        font-weight: bolder;

        color: #606264 !important;
        background-color: #fff !important;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: $input-border-radius;
        background-image: none;
        letter-spacing: 1px;
        &::placeholder {
            padding: 0 !important;
            color: #606264 !important;
            font-size: 16px;
            font-weight: bolder;
        }

        &:focus {
            border: $input-border-width solid #a961a9;
            color: #606264 !important;
        }

        &:hover {
            color: #606264 !important;
        }
        
    }
    .form-label{
        color: #ffffff !important;
        letter-spacing: 1px;
        font-size: 1rem;
        font-weight: 500;
    }
    .text-muted{
        color: $gray-800 !important;
    }
}
.wh-login-btn{
    font-weight: bold;
    color: $wh-login-btn-color;
    background-color: $wh-login-btn-bg-color;
    opacity: 1;
    transition: background-color 0.3s ease; 
    box-shadow: $wh-login-btn-box-shadow;
    transition: 0.4s;
    &:hover {
        color: $wh-login-btn-color;
        box-shadow: $wh-login-btn-box-shadow;
        background-color:  $wh-login-btn-bg-color;
    }
}
.wh-login-eye-icon{
    color: $wh-icon-eye-color !important;
}

.wh-background-image{
    margin: 0;
    padding: 0;
    position: relative;
}
.wh-background-image::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    background-image: $wh-background-image;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}